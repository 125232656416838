/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import cx from 'classnames';
import Img from 'gatsby-image';

const Image = ({ className, type = 'image', ...props}) => {
	const rootClass = cx('image', className, {
		[`image--${type}`]: type === "gif",
	});
	const {fluid, alt = '', ...otherProps} = props;
	const isGatsbyImage = type !== "gif" &&
		(Object.prototype.hasOwnProperty.call(props, 'fluid') ||
		Object.prototype.hasOwnProperty.call(props, 'fixed'));
	const fluidGif = (type === "gif" && fluid) ? true : false;

	// remove unwanted props for gifs so they don't leak into HTML markup
	delete otherProps.aspectRatio;
	delete otherProps.objectFit;

	const responsiveGifs = fluidGif && fluid?.reverse().map(
		(gif, index) => {

			return (
				<source key={`source-${index}`} media={gif.media || "(min-width: 0px)"} srcSet={gif.src} />
			);
		}
	);

	return (
		<>
			{isGatsbyImage ? (
				<Img className={rootClass} {...props} alt={alt} />
			) : (
				fluidGif ? (
					<picture className={rootClass} {...otherProps}>
						{responsiveGifs}
						<img src={fluid[fluid.length - 1].src} alt={alt} />
					</picture>
				) : (
					<img className={rootClass} {...props} alt={alt} loading="lazy" />
				)
			)}
		</>
	);
};

export default Image;
